import * as React from "react"
import fetch from "isomorphic-fetch"

const LOCAL_ENDPOINT = "http://localhost:8888/.netlify/functions/cartContext"
const PROD_ENDPOINT =
  "https://www.filmfroth.com/.netlify/functions/cartContext"

const CART_ENDPOINT =
  process.env.NODE_ENV === "development" ? LOCAL_ENDPOINT : PROD_ENDPOINT

const defaultValues = {
  cart: [],
  isOpen: false,
  loading: false,
  onOpen: () => {},
  onClose: () => {},
  addVariantToCart: () => {},
  removeLineItem: () => {},
  updateLineItem: () => {},
  checkout: {
    lineItems: [],
  },
  searchTags: [],
  addTagToSearch: () => {},
  removeTagToSearch: () => {}
}

export const StoreContext = React.createContext(defaultValues)

const isBrowser = typeof window !== `undefined`
const localStorageKey = `shopify_checkout_id`

export const StoreProvider = ({ children }) => {
  const [checkout, setCheckout] = React.useState(defaultValues.checkout)
  const [searchTags, setSearchTags] = React.useState(defaultValues.searchTags)
  const [loading, setLoading] = React.useState(false)
  const [didJustAddToCart, setDidJustAddToCart] = React.useState(false)

  const setCheckoutItem = checkout => {
    if (isBrowser) {
      localStorage.setItem(localStorageKey, checkout.id)
    }

    setCheckout(checkout)
  }

  React.useEffect(() => {
    const initializeCheckout = async () => {
      const existingCheckoutID = isBrowser
        ? localStorage.getItem(localStorageKey)
        : null
      fetch(CART_ENDPOINT, {
        method: "POST",
        mode: "cors",
        body: JSON.stringify({ method: "get", id: existingCheckoutID }),
      })
        .then(response => response.json())
        .then(data => setCheckoutItem(data.checkout))
    }

    initializeCheckout()
  }, [])

  const addVariantToCart = (variantId, quantity) => {
    setLoading(true)

    const checkoutID = checkout.id

    const lineItemsToUpdate = [
      {
        variantId,
        quantity: parseInt(quantity, 10),
      },
    ]
    fetch(CART_ENDPOINT, {
        method: "POST",
        mode: "cors",
        body: JSON.stringify({ method: "add", id: checkoutID, items: lineItemsToUpdate }),
      })
        .then(response => response.json())
        .then(data => {
          setCheckout(data.checkout)
          setLoading(false)
          setDidJustAddToCart(true)
          setTimeout(() => setDidJustAddToCart(false), 3000)
        })
  }

  const removeLineItem = (checkoutID, lineItemID) => {
    setLoading(true)

    fetch(CART_ENDPOINT, {
        method: "POST",
        mode: "cors",
        body: JSON.stringify({ method: "remove", id: checkoutID, items: lineItemID }),
      }).then(response => response.json())
      .then(data => {
        setCheckout(data.checkout)
        setLoading(false)
      })
  }

  const updateLineItem = (checkoutID, lineItemID, quantity) => {
    setLoading(true)

    const lineItemsToUpdate = [
      { id: lineItemID, quantity: parseInt(quantity, 10) },
    ]

    fetch(CART_ENDPOINT, {
      method: "POST",
      mode: "cors",
      body: JSON.stringify({
        method: "update",
        id: checkoutID,
        items: lineItemsToUpdate,
      }),
    })
      .then(response => response.json())
      .then(data => {
        setCheckout(data.checkout)
        setLoading(false)
      })
  }

  const addTagToSearch = (tag) => {
    if(searchTags.indexOf(tag) >= 0) {
      return
    }
    const newTags = searchTags
    newTags.push(tag)
    setSearchTags(newTags)
  }

  const removeTagToSearch = (tag) => {
    const newTags = searchTags.filter(t => t !== tag)
    setSearchTags(newTags)
  }

  return (
    <StoreContext.Provider
      value={{
        ...defaultValues,
        addVariantToCart,
        removeLineItem,
        updateLineItem,
        checkout,
        loading,
        didJustAddToCart,
        searchTags,
        addTagToSearch,
        removeTagToSearch
      }}
    >
      {children}
    </StoreContext.Provider>
  )
}
